// Note: polyfills are part of webpack entry points
import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import {
  Router,
  LocationProvider,
  RouteComponentProps,
  globalHistory,
} from '@reach/router';
import { MuiThemeProvider } from '@material-ui/core/styles';

import './App.css';
import analytics from './utils/analytics';
import createApolloClient from './modules/core/services/createApolloClient';
import {
  authService,
  AuthProvider,
  useAuthContext,
} from './modules/core/providers/AuthProvider';
import Loader from './components/Loader';
import { useViewerQuery } from './queries/ViewerQuery';
import { muiTheme } from './modules/ui/theme';

// rotues
import MarketingPageRoute from './modules/app/routes/MarketingPageRoute';
import LoginRoute from './modules/core/routes/LoginRoute';
import LogoutRoute from './modules/core/routes/LogoutRoute';
import RegisterRoute from './modules/core/routes/RegisterRoute';
import ViewerHomeRoute from './modules/viewer/routes/ViewerHomeRoute';
import { GroupRoute } from './modules/groups/routes/GroupRoute';
import {
  NodeRouteViaQueryParam,
  NodeRouteViaRouteParam,
} from './modules/NodeRoute';
import ScanQRRoute from './modules/viewer/routes/ScanQrRoute';
import GroupsInvitesRoute from './modules/viewer/routes/GroupsInvitesRoute';
import AuthCallbackRoute from './modules/app/routes/AuthCallbackRoute';
import { EditOwnCardRoute } from './modules/Profile/Screens/EditOwnCardRoute';
import idx from './utils/idx';
import { DrawerNavContext } from './modules/Navigation/DrawerNavigation';
import { MinglSnackbar } from './modules/ui/Snackbar';

// TODO: https://philipwalton.com/articles/deploying-es2015-code-in-production-today/

function createLazyRoute<T extends RouteComponentProps>(
  RouteComponent: React.ComponentType<T>
) {
  return function lazyRoute(props: T) {
    return (
      <React.Suspense fallback={<Loader />}>
        <RouteComponent {...props} />
      </React.Suspense>
    );
  };
}

const AsyncSettingsRoute = createLazyRoute(
  React.lazy(() => import('./modules/viewer/routes/SettingsRoute'))
);
const AsyncSearchRoute = createLazyRoute(
  React.lazy(() => import('./modules/app/routes/SearchRoute'))
);
const AsyncMinglAdminRoot = createLazyRoute(
  React.lazy(() => import('./modules/admin/routes/AdminRoot'))
);
const AsyncTermsAndConditions = createLazyRoute(
  React.lazy(() => import('./modules/legal/routes/TermsAndConditions'))
);
const AsyncPrivacyPolicy = createLazyRoute(
  React.lazy(() => import('./modules/legal/routes/PrivacyPolicy'))
);
const AsyncCreateGroupUnavailable = createLazyRoute(
  React.lazy(() => import('./modules/app/components/CreateGroupUnavailable'))
);
const AsyncGroupsRoute = createLazyRoute(
  React.lazy(() => import('./modules/groups/routes/GroupsRoute'))
);
const AsyncGroupMembersRoute = createLazyRoute(
  React.lazy(() => import('./modules/groups/routes/GroupMembers'))
);

const apolloClient = createApolloClient(authService);

function usePageviewTracker() {
  React.useEffect(() => {
    analytics.load();
    analytics.page(); // tracks the first page opened

    const _removeHistoryListener = globalHistory.listen(() => {
      analytics.page(); // tracks page navigation after the initial page
    });

    return () => {
      _removeHistoryListener();
    };
  }, []);
}

function useIdentifyUserWithAnalytics() {
  const { isAuthenticated } = useAuthContext();
  const { data } = useViewerQuery({ skip: !isAuthenticated });
  const viewerId = idx(data, _ => _!.viewer!.id);

  React.useEffect(() => {
    if (isAuthenticated && viewerId) {
      analytics.identify(viewerId);
    } else if (!isAuthenticated) {
      analytics.identify(); // un-identifies viewer
    }
  }, [isAuthenticated, viewerId]);

  return null;
}

function App() {
  usePageviewTracker();
  useIdentifyUserWithAnalytics();

  return (
    <Router className="h-100">
      <MarketingPageRoute path="/" />

      {/* display cards */}
      <NodeRouteViaRouteParam path="/c/:id" returnPath={'/home'} />
      <NodeRouteViaRouteParam path="/:id" returnPath={'/home'} />
      <NodeRouteViaRouteParam path="/u/:id" returnPath={'/home'} />

      {/* viewer routes */}
      <ViewerHomeRoute path="/home" />

      {/* Groups */}
      <GroupRoute path="/groups/:id" returnPath="/groups" />
      <AsyncGroupMembersRoute path="/groups/:id/members" />
      <AsyncGroupsRoute path="/groups/" returnPath="/home" />

      {/* forms */}
      <AsyncSearchRoute path="search" />
      <AsyncSettingsRoute path="settings" returnTo={'/home'} />
      <EditOwnCardRoute path="me/edit" returnTo={'/home'} />

      {/* Features */}
      <ScanQRRoute path="/qr" />
      <NodeRouteViaQueryParam path="/qr/match" returnPath={'/qr'} />
      <GroupsInvitesRoute path="/groups/invites" />

      {/* Auth */}
      <LoginRoute path="/login" />
      <LogoutRoute path="/logout" />
      <RegisterRoute path="/register" />
      <AuthCallbackRoute path="/joinMinglCallback" />
      <AuthCallbackRoute path="/authCallback" />

      {/* Mingl admin */}
      <AsyncMinglAdminRoot path="/mingladmin" />
      <AsyncMinglAdminRoot path="/mingladmin/*" />

      {/* Misc/offical */}
      {/* TODO: Make this reusable component for unavailable features */}
      <AsyncCreateGroupUnavailable path="/groups/create" returnPath="/home" />
      <AsyncTermsAndConditions
        path="/app/termsandconditions"
        returnPath="/home"
      />
      <AsyncPrivacyPolicy path="/app/privacypolicy" returnPath="/home" />
    </Router>
  );
}

export default function AppContainer() {
  const [drawerNavOpen, setDrawerNavOpen] = React.useState(false);
  const appDrawerContextValue = React.useMemo(
    () => ({
      drawerNavOpen,
      setDrawerNavOpen: (isOpen: boolean) => setDrawerNavOpen(isOpen),
    }),
    [drawerNavOpen, setDrawerNavOpen]
  );

  return (
    <ApolloProvider client={apolloClient}>
      <AuthProvider>
        <LocationProvider history={globalHistory}>
          <MuiThemeProvider theme={muiTheme}>
            <MinglSnackbar>
              <DrawerNavContext.Provider value={appDrawerContextValue}>
                <App />
              </DrawerNavContext.Provider>
            </MinglSnackbar>
          </MuiThemeProvider>
        </LocationProvider>
      </AuthProvider>
    </ApolloProvider>
  );
}
