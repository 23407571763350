import * as React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import idx from '../../../utils/idx';
import { navigate } from '@reach/router';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import Spinner from '../../../components/icons/Spinner';
import { useUpdateGroupMutation } from '../../../queries/UpdateGroupMutation';
import { useDeleteItemMutation } from '../../../queries/DeleteItemMutation';
import { getGroupListQueryName } from '../../../queries/GroupListQuery';
import { TextEllipsis } from '../../Common/TextEllipsis';
import AlertDialog from '../../../components/AlertDialog';
import { useSnackbar } from '../../../modules/ui/Snackbar';
import {
  GetNode_node_Group,
  RequestStatusEnum,
} from '../../../__generated/apollogen-types';

type GroupFormValuesT = { name: string; description: string };

class FormikGroupForm extends Formik<{}, GroupFormValuesT> {}

const groupValidation = yup.object().shape({
  name: yup.string().required('Required'),
  description: yup.string().default(''),
});

const TitleAndDescription = (props: {
  description: string;
  name: string;
  setEditMode: (focusEl?: 'name' | 'description') => void;
  editMode: boolean;
  viewerCanEdit: boolean;
}) => {
  return (
    <>
      <div className="flex flex-column ml3 mb3 mb0-m white">
        <span className={`f4 f3-ns f2-l fw6 mb3`}>{props.name}</span>
        <TextEllipsis
          text={props.description || 'add a description'}
          onClick={() =>
            props.viewerCanEdit &&
            !props.description &&
            props.setEditMode('description')
          }
          textClassName={clsx('f5', {
            'mingl-gray400': props.viewerCanEdit && !props.description,
            pointer: props.viewerCanEdit && !props.description,
          })}
          buttonClassName={'white'}
        />
      </div>
      {props.viewerCanEdit ? (
        <div className="flex items-start justify-end">
          <Button
            variant="text"
            color="default"
            children="edit"
            onClick={() => {
              props.setEditMode('name');
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export const DisplayAndManageGroupInfo = (props: {
  group: GetNode_node_Group;
  refetchGroupInfo: () => void;
}) => {
  const [deleteItem] = useDeleteItemMutation();
  const [updateGroup] = useUpdateGroupMutation();
  const [editMode, setEditMode] = React.useState(false);
  const [autofocus, setAutofocus] = React.useState('name');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { setSnackbar } = useSnackbar();
  const materialTheme = useTheme();
  const largeScreenSize = useMediaQuery(materialTheme.breakpoints.up('md'));

  const onSubmitForm = (formValues: GroupFormValuesT) => {
    updateGroup({
      variables: {
        input: {
          group: {
            id: props.group.id,
            name: formValues.name,
            description: formValues.description,
            icon: props.group.icon,
          },
        },
      },
    }).then(() => {
      setEditMode(false);
      props.refetchGroupInfo();
    });
  };

  const onDeleteGroup = () => {
    const errorMessage =
      'There was an error deleting the group, please try again.';

    deleteItem({
      variables: { input: { id: props.group.id } },
      awaitRefetchQueries: true,
      refetchQueries: () => {
        return [getGroupListQueryName()];
      },
    })
      .then(res => {
        const { status, message } = idx(res, _ => _.data!.deleteItem) || {
          status: RequestStatusEnum.ERROR,
          message: errorMessage,
        };
        setSnackbar(message, status);
        setDialogOpen(false);
        if (status === 'OK') {
          navigate('/groups');
        }
      })
      .catch(error => {
        setSnackbar(errorMessage, 'error');
      });
  };

  return (
    <div className="pl0 pl3-m pl3-l mb2 flex-auto">
      <div className="flex flex-column flex-row-m flex-row-l flex-row-ns justify-between flex-auto">
        {editMode ? (
          <FormikGroupForm
            initialValues={{
              name: props.group.name,
              description: props.group.description || '',
            }}
            onSubmit={onSubmitForm}
            validationSchema={groupValidation}
            validateOnBlur={true}
            validateOnChange={true}
          >
            {({
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            }) => {
              return (
                <form onSubmit={handleSubmit} className="w-100">
                  <div className="flex flex-column flex-row-m flex-row-l">
                    <div className="flex flex-column pl3 w-100">
                      <div className="mb3 w-100">
                        <TextField
                          id="name"
                          label="Group Title"
                          variant="outlined"
                          value={values.name}
                          onChange={handleChange}
                          error={errors.name}
                          onBlur={handleBlur}
                          autoFocus={autofocus === 'name'}
                          size="small"
                        />
                      </div>
                      <TextField
                        id="description"
                        multiline={true}
                        label="Group Description"
                        variant="outlined"
                        value={values.description}
                        onChange={handleChange}
                        error={errors.description}
                        onBlur={handleBlur}
                        autoFocus={autofocus === 'description'}
                        size="small"
                      />
                    </div>
                    <div className="flex flex-row flex-column-m flex-column-l flex-wrap items-center ml3 mt3 mt0-m mt0-l">
                      <ButtonGroup
                        color="default"
                        orientation={
                          largeScreenSize ? 'vertical' : 'horizontal'
                        }
                      >
                        <Button
                          children="Cancel"
                          onClick={() => {
                            setEditMode(!editMode);
                          }}
                        />

                        {isSubmitting ? (
                          <Spinner className="tc" color="white" size={32} />
                        ) : (
                          <Button children="Save" type="submit" />
                        )}

                        {props.group.permissions.viewerCanDeleteGroup && (
                          <Button
                            children="Delete Group"
                            onClick={() => setDialogOpen(true)}
                          />
                        )}
                      </ButtonGroup>
                      <AlertDialog
                        open={dialogOpen}
                        handleClose={() => setDialogOpen(false)}
                        title="Delete group?"
                        contentText="This action can not be undone."
                        handleAction={onDeleteGroup}
                      />
                    </div>
                  </div>
                </form>
              );
            }}
          </FormikGroupForm>
        ) : (
          <TitleAndDescription
            name={props.group.name}
            description={props.group.description || ''}
            setEditMode={focusEl => {
              setAutofocus(focusEl || 'name');
              setEditMode(true);
            }}
            editMode={editMode}
            viewerCanEdit={props.group.permissions.viewerCanEditGroupInfo}
          />
        )}
      </div>
    </div>
  );
};
