import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import {
  InMemoryCache,
  defaultDataIdFromObject,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import once from 'lodash-es/once';
import introspectionQueryResultData from '../../../__generated/fragmentTypes';
import analytics, { analyticsEvents } from '../../../utils/analytics';

import { AuthServiceT } from './auth';

interface Node {
  id: string;
  __typename?: string;
}

export default function createApolloClient(authService: AuthServiceT) {
  const httpLink = createHttpLink({
    uri: '/graphql',
  });

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return authService.getAuthState().then(({ accessToken }) => {
      return {
        headers: {
          ...headers,
          authorization: accessToken ? `Bearer ${accessToken}` : '',
        },
      };
    });
  });

  const errorLink = onError(({ graphQLErrors = [], networkError }) => {
    const triggerUnauthenticateUser = once(() => {
      analytics.track(analyticsEvents.apollo.unauthenticatedUser, {
        trigger: 'apollo_error_link',
      });

      authService.unauthenticateUser();
      // TODO: Notify 'Your session expired, please sign in again'
    });

    graphQLErrors.map(gqlError => {
      if (gqlError.message.indexOf('Unauthenticated') > -1) {
        triggerUnauthenticateUser();
      }
    });
  });

  const client = new ApolloClient({
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network' as 'cache-and-network',
      },
      query: {
        fetchPolicy: 'network-only' as 'network-only',
      },
    },
    link: ApolloLink.from([
      authLink,
      errorLink,
      httpLink, // should be final link
    ]),
    cache: new InMemoryCache({
      fragmentMatcher,
      dataIdFromObject: (object: Node) => {
        if (object.id && object.__typename) {
          return `${object.__typename}:${object.id}`;
        }
        return defaultDataIdFromObject(object);
      },
    }),
  });

  return client;
}
