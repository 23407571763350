import * as React from 'react';
import idx from '../../../utils/idx';
import { RouteComponentProps, navigate } from '@reach/router';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import Button from '@material-ui/core/Button';
import * as queryString from 'query-string';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useRect } from '@reach/rect';
import { Helmet } from 'react-helmet';

import { useNodeQuery } from '../../../queries/GetNode';
import { NavComponents } from '../../Navigation/HeaderNavigationComponents';
import { analyticsEvents, wait } from '../../../utils/analytics';
import Loader from '../../../components/Loader';
import PageContainer from '../../../containers/PageContainer';
import { makeDynamicLink } from '../../../utils/minglUrlUtil';
import {
  GroupMemberRole,
  GetNode_node_Group,
  CardsInGroupQueryOrder,
  CardNodeFragment,
} from '../../../__generated/apollogen-types';
import ErrorMessage from '../../../components/ErrorMessage';
import { userDevice } from '../../../utils/userDevice';
import { useAuthContext } from '../../core/providers/AuthProvider';
import { useHandledJoinGroupMutation } from '../../../queries/JoinGroupMutation';
import { useQueryParams } from '../../Common/useQueryParams';
import { useGroupItemsQuery } from '../../../queries/GroupItemsQuery';
import { GroupMembersList } from '../common';
import * as cardNavigation from '../../card/utils/cardNavigation';
import { colors } from '../../ui/theme';
import { ChatPopup } from '../../ui/Popups/ChatPopup';
import { CopyGroupLinkPopup } from '../../ui/Popups/CopyGroupLinkPopup';
import { useGroupAdminsQuery } from '../../../queries/GroupAdminsQuery';
import { useSnackbar } from '../../ui/Snackbar';
import { DisplayAndManageGroupInfo } from '../components/DisplayAndManageGroupInfo';
import { DisplayAndManageGroupImage } from '../components/DisplayAndManageGroupImage';
import { GroupEnquiriesSection } from '../components/GroupEnquiries';
import ResourcesSection from '../components/ResourcesSection';
import { useGroupResources } from '../../../queries/GroupResourcesQuery';
import UserIcon from '../../../components/icons/UserIcon';

function ContentJoinGroup(props: {
  node: GetNode_node_Group;
  onJoinGroup: () => void;
}) {
  const { node, onJoinGroup } = props;
  const { isAuthenticated, triggerSignUp, triggerLogin } = useAuthContext();

  // automatically join the group when the user returns after authenticating
  const { joinOnReturn } = useQueryParams(
    ['joinOnReturn'],
    window.location.search
  );

  React.useEffect(() => {
    if (isAuthenticated && joinOnReturn == '1') {
      onJoinGroup();
    }
  }, [joinOnReturn, onJoinGroup, isAuthenticated]);

  const joinOrAuthenticateButtons = () => {
    if (isAuthenticated) {
      return (
        <Button
          color="primary"
          variant="contained"
          size="large"
          className="w-100"
          onClick={onJoinGroup}
          children="Join group"
        />
      );
    }

    return (
      <>
        <div className="mb3">
          <Button
            color="primary"
            variant="contained"
            size="large"
            children="Sign Up and Join"
            className="w-100"
            onClick={() => {
              analytics.track(analyticsEvents.groupPage.signupAndJoin, {
                title: 'Sign Up and Join',
                nodeId: node.id,
              });
              triggerSignUp(window.location.pathname + '?joinOnReturn=1');
            }}
          />
        </div>
        <Button
          color="secondary"
          variant="outlined"
          children="Login and Join"
          className="w-100"
          onClick={() => {
            analytics.track(analyticsEvents.webReferrals.groupReferral, {
              title: 'Login and Join',
              nodeId: node.id,
            });
            triggerLogin(window.location.pathname + '?joinOnReturn=1');
          }}
        />
      </>
    );
  };

  return (
    <React.Fragment>
      <p className="f4 lh-copy white tc">
        Join the group to get access to all the other members and group chat!
      </p>
      <div
        className={clsx(
          'center mb3',
          'flex flex-column flex-row-ns items-center justify-around'
        )}
      >
        <div className="w-100 w-40-ns">{joinOrAuthenticateButtons()}</div>
      </div>
    </React.Fragment>
  );
}

const Breakline = (props: { className?: string }) => (
  <hr
    className={`mingl-blue900 ${props.className && props.className}`}
    style={{
      boxShadow: '0px 1px 1px rgba(255, 255, 255, 0.2)',
      borderTopWidth: 0.7,
    }}
  />
);

interface GroupPageProps {
  group: GetNode_node_Group;
  onJoinGroup: () => void;
  refetchGroup: () => void;
}

const GroupPage = (props: GroupPageProps) => {
  const { group } = props;
  const [showChatPopup, setShowChatPopup] = React.useState(false);
  const [showGroupLinkPopup, setShowGroupLinkPopup] = React.useState(false);
  const [minimizedView, setMinimizedView] = React.useState(true);

  const groupId = group.id;
  const order = CardsInGroupQueryOrder.ALPHABETICAL;
  const {
    data: membersData,
    loading: loadingMembers,
    fetchMore: fetchMoreMembers,
  } = useGroupItemsQuery({ variables: { input: { groupId, order } } });

  const { data: adminsData, loading: loadingAdmins } = useGroupAdminsQuery({
    id: group.id,
  });
  const admins = (idx(adminsData, _ => _!.group!.admins.edges) || []).map(
    adminEdge => adminEdge.node.card
  );

  const {
    data: resourcesData,
    loading: loadingResources,
    refetch: refetchResources,
  } = useGroupResources({ input: { groupId } });

  const groupResources =
    idx(resourcesData, _ => _!.groupResources!.edges.map(edge => edge.node)) ||
    [];

  React.useEffect(() => {
    analytics.track(analyticsEvents.groupPage.opened, {
      nodeId: group.id,
      viewerRole: group.viewerRole,
      hasAppStore: userDevice.hasAppStore,
    });
  }, [group.id, group.viewerRole]);

  const cardsInGroup = React.useMemo(() => {
    return (
      idx(membersData, _ => _!.groupItems.edges.map(edge => edge.node.card)) ||
      []
    );
  }, [membersData]);

  const totalCount = idx(membersData, _ => _!.groupItems.totalCount) || 0;

  const onChatTapped = () => {
    setShowChatPopup(true);
  };

  return (
    <div className="w-100 w-80-ns center mw8">
      <Helmet
        onChangeClientState={() => {
          // @ts-ignore
          window.prerenderReady = true;
        }}
      >
        <title children={group.name} />
        <meta property="og:title" content={group.name} />
        <meta property="og:description" content={group.description || ''} />
        <meta property="og:site_name" content={`${group.name} on Mingl`} />
        <meta property="og:image" content={group.icon} />
      </Helmet>

      <div className="pt3 mb3 flex items-start flex-row">
        <DisplayAndManageGroupImage group={group} />
        <DisplayAndManageGroupInfo
          group={group}
          refetchGroupInfo={props.refetchGroup}
        />
      </div>
      <Breakline className="mb3 mt2" />
      {group.viewerRole === GroupMemberRole.NONE ? (
        <>
          {groupResources.length > 0 && (
            <ResourcesSection
              key={group.id}
              group={group}
              minimizedView={minimizedView}
              setMinimizedView={setMinimizedView}
            />
          )}
          <ContentJoinGroup node={group} onJoinGroup={props.onJoinGroup} />
        </>
      ) : (
        <>
          <div className="flex flex-column-reverse flex-row-l">
            <div className="flex-auto w-60-l">
              <h3 className="f4 fw4 lh-title white mt0 mb2">Admins</h3>
              <GroupMembersList cards={admins} onChatTapped={onChatTapped} />
              {loadingAdmins && <CircularProgress />}
              <div className="flex items-baseline justify-between white mt3 mb2">
                <div className="flex items-baseline">
                  <h3 className="f4 fw4 lh-title mr2">Members</h3>
                  <UserIcon size={16} color={'white'} />
                  <p className="ma0 ml1" children={totalCount} />
                </div>
                <p
                  className="ma0 pl3 pointer"
                  children="See All"
                  onClick={() => navigate(`${group.id}/members`)}
                />
              </div>
              <GroupMembersPreviewGrid cards={cardsInGroup} />
              {loadingMembers && <CircularProgress />}
              {group.permissions.viewerCanInviteMembers && (
                <div className="flex w-100 justify-end">
                  <Button
                    color="primary"
                    variant="contained"
                    children="Invite People"
                    onClick={() => {
                      setShowGroupLinkPopup(true);
                    }}
                  />
                </div>
              )}
              <GroupEnquiriesSection
                groupId={props.group.id}
                onCardPressed={card =>
                  navigate(cardNavigation.getCardPath(card))
                }
              />
            </div>
            <div
              className="flex flex-column flex-auto ml4-l w-40-l"
              style={{ minWidth: '20rem' }}
            >
              <ResourcesSection
                group={group}
                minimizedView={minimizedView}
                setMinimizedView={setMinimizedView}
              />
            </div>
          </div>
        </>
      )}
      <CopyGroupLinkPopup
        show={showGroupLinkPopup}
        close={() => setShowGroupLinkPopup(false)}
        groupLink={group.publicURL || ''}
      />
      <ChatPopup show={showChatPopup} close={() => setShowChatPopup(false)} />
    </div>
  );
};

const Avatar = (props: { uri: string; size: number }) => (
  <img
    src={props.uri}
    className="items-center justify-center"
    style={{
      width: props.size,
      height: props.size,
      borderRadius: props.size / 2,
      border: '2px solid white',
      objectFit: 'cover',
    }}
  />
);

const getTwoFirstWords = (text: string) => {
  const wordArray = text.split(' ');

  if (wordArray.length < 2) {
    return text;
  }
  return `${wordArray[0]} ${wordArray[1]} ${wordArray[2] ? '...' : ''}`;
};

function _GroupMembersPreviewGrid(props: { cards: CardNodeFragment[] }) {
  const AVATAR_SIZE = 60;
  const columnWidth = AVATAR_SIZE + 16;

  const ref = React.useRef<HTMLDivElement>(null);
  const rect = useRect(ref);
  let cardsToRender = props.cards.slice(0, 20);

  if (rect) {
    const cardsPerRow = Math.floor(rect.width / columnWidth);
    const needsTwoRows = cardsToRender.length > cardsPerRow;

    cardsToRender = needsTwoRows
      ? cardsToRender.slice(0, cardsPerRow * 2)
      : cardsToRender.slice(0, cardsPerRow);
  }
  return (
    <div ref={ref} className="flex flex-row flex-wrap justify-around">
      {cardsToRender.map(card => (
        <div key={card.id} className="pb3 flex" style={{ width: columnWidth }}>
          <div
            onClick={() => navigate(cardNavigation.getCardPath(card))}
            className="pointer"
          >
            <div className="flex flex-row justify-center mb2">
              <Avatar uri={card.imageThumbnailURL} size={AVATAR_SIZE} />
            </div>
            <p
              className="tc f7 white ph1 pb0"
              style={{ wordBreak: 'break-word' }}
            >
              {getTwoFirstWords(card.title)}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
const GroupMembersPreviewGrid = React.memo(_GroupMembersPreviewGrid);

function GroupRoute(props: RouteComponentProps & { returnPath: string }) {
  const nodeUri = props.uri || window.location.pathname;

  const { setSnackbar } = useSnackbar();
  const nodeQuery = useNodeQuery({ variables: { id: nodeUri } });
  const node = idx(nodeQuery, _ => _.data!.node);
  const nodeId = node ? node.id : '';
  const { refetch: refetchGroup } = nodeQuery;

  const joinGroup = useHandledJoinGroupMutation();
  const onJoinGroup = React.useCallback(async () => {
    const { status, message } = await joinGroup({
      variables: { input: { id: nodeId } },
    });
    refetchGroup();
    setSnackbar(message, status);
  }, [joinGroup, nodeId, setSnackbar, refetchGroup]);

  const groupNode = node && node.__typename === 'Group' ? node : undefined;
  const viewerRole = groupNode ? groupNode.viewerRole : GroupMemberRole.NONE;
  React.useEffect(() => {
    if (viewerRole === GroupMemberRole.NONE) {
      return;
    }

    // cleanup auto-join parameter
    const queryParams = queryString.parse(window.location.search);
    if (!isEmpty(queryParams)) {
      const path = window.location.pathname;
      let query = queryString.stringify(omit(queryParams, 'joinOnReturn'));
      query = query ? `?${query}` : '';
      window.history.replaceState(null, '', path + query);
    }
  }, [viewerRole]);

  const dynamicLink = makeDynamicLink({
    medium: 'groupPage',
    source: 'groupPage',
    dynamicLinkDomain: 'mingl.page.link',
    link: `https://mingl.no${nodeUri}`,
  });

  return (
    <PageContainer
      mobileNavItem={props.returnPath}
      redirectUnauthenticated={false}
      mobileActionItems={
        <NavComponents.GetTheApp
          title="Open in app"
          href={dynamicLink}
          analyticsData={{ event: 'Group Referral' }}
        />
      }
      mainStyle={{ alignContent: 'center', padding: '10px 20px' }}
    >
      {!node && nodeQuery.loading && <Loader color={colors.green100} />}

      {!node && nodeQuery.error && (
        <ErrorMessage
          error={nodeQuery.error}
          retry={() => nodeQuery.refetch()}
          message="An error occurred while loading the group"
        />
      )}

      {!node && !nodeQuery.loading && !nodeQuery.error && (
        <div className="measure-wide center">
          <h2 className="f3 fw4 lh-title mt5 mb3 white">
            Couldn&apos;t find the group you&apos;re looking for
          </h2>
          <p className="f5 white">
            Either it does not exist, or you don&apos;t have permission to view
            it
          </p>
        </div>
      )}

      {groupNode && (
        <GroupPage
          key={groupNode.viewerRole}
          group={groupNode}
          onJoinGroup={onJoinGroup}
          refetchGroup={refetchGroup}
        />
      )}
    </PageContainer>
  );
}

export { GroupRoute, ResourcesSection };
