import React from 'react';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import Linkify from 'linkifyjs/react';

import {
  useGroupEnquiriesQuery,
  getGroupEnquiriesQueryName,
} from '../../../queries/GroupEnquiriesQuery';
import { useSnackbar } from '../../ui/Snackbar';
import { colors, spacing } from '../../../modules/ui/theme';
import { muiTheme } from '../../ui/theme';
import {
  RequestStatusEnum,
  MinimalCardFragment,
  GroupEnquiryFragment,
  GroupEnquiries_group_enquiries_edges,
} from '../../../__generated/apollogen-types';
import idx from '../../../utils/idx';
import { useCreateGroupEnquiryMutation } from '../../../queries/CreateGroupEnquiryMutation';
import { useRemoveGroupEnquiryMutation } from '../../../queries/RemoveGroupEnquiryMutation';
import { useUpdateGroupEnquiryMutation } from '../../../queries/UpdateGroupEnquiryMutation';
import { useHandledReplyToGroupEnquiryMutation } from '../../../queries/ReplyToGroupEnquiryMutation';
import AlertDialog from '../../../components/AlertDialog';

const MAX_CHAR_LENGTH = 256;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputfield: {
      backgroundColor: colors.blue900,
      flex: 1,
      resize: 'none' as 'none',
    },
  })
);

const HeaderSection = (props: {
  title: string;
  children?: React.ReactNode;
}) => (
  <div className="flex justify-between items-center flex-wrap mb2">
    <Typography
      variant="h6"
      style={{ marginBottom: muiTheme.spacing(1) }}
      children={props.title}
      className="fw6 mv2 white"
    />
    {props.children && props.children}
  </div>
);

const TextArea = (props: {
  setText: React.Dispatch<React.SetStateAction<string>>;
  text: string;
  classes: Record<'inputfield', string>;
}) => (
  <div
    className="pa2 items-center justify-center mb2 br2 flex flex-row flex-row"
    style={{ backgroundColor: colors.blue900 }}
  >
    <TextareaAutosize
      className={`${props.classes.inputfield} b--none w-100 white pb1`}
      rows={3}
      rowsMax={6}
      maxLength={MAX_CHAR_LENGTH}
      placeholder="E.g. Seeking someone who knows about maritime law."
      value={props.text}
      onChange={e => props.setText(e.target.value)}
    />
  </div>
);

interface OwnUserEnquiryProps {
  groupId: string;
  viewerCanCreateEnquiry: boolean;
  ownEnquiry: GroupEnquiryFragment | null;
  onCardPressed: (card: MinimalCardFragment) => void;
}

const OwnUserEnquiry = (props: OwnUserEnquiryProps) => {
  const { groupId, viewerCanCreateEnquiry, ownEnquiry, onCardPressed } = props;
  const { setSnackbar } = useSnackbar();
  const classes = useStyles();

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [text, setText] = React.useState(ownEnquiry ? ownEnquiry.text : '');
  React.useEffect(() => {
    if (ownEnquiry) {
      setText(ownEnquiry.text);
    }
  }, [ownEnquiry]);

  const [createGroupEnquiry] = useCreateGroupEnquiryMutation();
  const [removeGroupEnquiry] = useRemoveGroupEnquiryMutation();
  const [updateGroupEnquiry] = useUpdateGroupEnquiryMutation();

  const _defaultMessage = 'Failed to post';
  const title = ownEnquiry ? 'Your answer' : 'What are you looking for?';
  const enquiryId = ownEnquiry ? ownEnquiry.id : '';

  const handleSubmit = () => {
    createGroupEnquiry({
      variables: { input: { groupId, text } },
      refetchQueries: () => [getGroupEnquiriesQueryName()],
    }).then(res => {
      const { message, status } = idx(res, _ => _.data!.createGroupEnquiry) || {
        status: RequestStatusEnum.ERROR,
        message: _defaultMessage,
      };
      setSnackbar(
        message,
        status === RequestStatusEnum.OK ? 'success' : 'error'
      );
    });
  };
  const handleUpdatePost = () => {
    updateGroupEnquiry({
      variables: { input: { enquiryId, text } },
      refetchQueries: () => [getGroupEnquiriesQueryName()],
    }).then(res => {
      const { message, status } = idx(res, _ => _.data!.updateGroupEnquiry) || {
        status: RequestStatusEnum.ERROR,
        message: _defaultMessage,
      };
      setSnackbar(
        message,
        status === RequestStatusEnum.OK ? 'success' : 'error'
      );
      setEditMode(false);
    });
  };
  const handleRemovePost = () => {
    removeGroupEnquiry({
      variables: { input: { enquiryId } },
      refetchQueries: () => [getGroupEnquiriesQueryName()],
    }).then(res => {
      const { message, status } = idx(res, _ => _.data!.removeGroupEnquiry) || {
        status: RequestStatusEnum.ERROR,
        message: _defaultMessage,
      };
      setSnackbar(
        message,
        status === RequestStatusEnum.OK ? 'success' : 'error'
      );
      setEditMode(false);
      setText('');
      setAlertOpen(false);
    });
  };

  if (viewerCanCreateEnquiry) {
    return (
      <div className="flex flex-column mt2 mb4">
        <HeaderSection title={title} />
        <TextArea text={text} setText={setText} classes={classes} />
        <Button
          className="self-end"
          color="primary"
          variant="contained"
          children={'POST'}
          onClick={() => handleSubmit()}
        />
      </div>
    );
  }
  if (ownEnquiry) {
    return (
      <div className="flex flex-column mt2 mb4">
        <HeaderSection
          title={title}
          children={
            <EditActions
              editMode={editMode}
              setEditMode={setEditMode}
              updatePost={handleUpdatePost}
              handleRemovePost={() => setAlertOpen(true)}
            />
          }
        />
        {editMode && (
          <TextArea text={text} setText={setText} classes={classes} />
        )}
        {!editMode && ownEnquiry && (
          <EnquiryCard
            enquiry={ownEnquiry}
            onReplySubmit={null}
            onCardPressed={onCardPressed}
          />
        )}
        <AlertDialog
          open={alertOpen}
          handleClose={() => setAlertOpen(false)}
          title="Remove post?"
          contentText="You can create a new one if you remove this."
          handleAction={handleRemovePost}
        />
      </div>
    );
  }
  return null;
};

interface EditActionsProps {
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  editMode: boolean;
  updatePost: () => void;
  handleRemovePost: () => void;
}
const EditActions = (props: EditActionsProps) => {
  if (!props.editMode) {
    return (
      <Button
        onClick={() => props.setEditMode(!props.editMode)}
        children="EDIT"
      />
    );
  }
  return (
    <ButtonGroup color="default">
      <Button children="REMOVE" onClick={props.handleRemovePost} />
      <Button children="SAVE" onClick={props.updatePost} />
      <Button children="CANCEL" onClick={() => props.setEditMode(false)} />
    </ButtonGroup>
  );
};

interface EnquiryCardProps {
  enquiry: GroupEnquiryFragment;
  onCardPressed: (card: MinimalCardFragment) => void;
  onReplySubmit: ((enquery: GroupEnquiryFragment, text: string) => void) | null;
}

const EnquiryCard = (props: EnquiryCardProps) => {
  const [value, onChangeText] = React.useState('');
  const classes = useStyles();
  const { onReplySubmit, enquiry } = props;
  const { text, id, card } = enquiry;

  const handleSubmit = React.useCallback(() => {
    if (typeof onReplySubmit === 'function') {
      onReplySubmit(enquiry, value);
    }
  }, [value, onReplySubmit, enquiry]);
  return (
    <div
      className="br3 pa2"
      style={{
        backgroundColor: colors.blue700,
        boxShadow:
          '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      }}
    >
      <div className="flex">
        <img
          style={{
            height: '35px',
            width: '35px',
            borderRadius: 999,
            minWidth: '35px',
          }}
          src={card.imageThumbnailURL}
        />
        <div className="ml2 truncate">
          <div className="flex mv1 white">
            <p className="fw6 ma0 ">{card.title}</p>
            <p className="ma0 ml2 fw2">{card.subtitle}</p>
          </div>
          <div className="flex">
            <p className="f2 white ma0 " children={'“'} />
            <p
              className="white mb2 ml1 mt1 ws-normal"
              children={
                <Linkify options={{ className: 'linkify' }}> {text} </Linkify>
              }
            />
          </div>
        </div>
      </div>
      <div>
        {props.enquiry.viewerCanRespond && props.onReplySubmit && (
          <div
            className="flex justify-between items-center pa2 w-100 br2"
            style={{ backgroundColor: colors.blue900 }}
          >
            <TextareaAutosize
              className={`${classes.inputfield} b--none w-100 white`}
              rows={1}
              rowsMax={6}
              maxLength={MAX_CHAR_LENGTH}
              onChange={e => onChangeText(e.target.value)}
              value={value}
              placeholder={`How can you help ${card.title}?`}
            />
            <Button
              className="white ma0 self-start"
              onClick={handleSubmit}
              children={'Reply'}
            />
          </div>
        )}
        {!props.enquiry.viewerCanRespond && value !== '' && (
          <p className="ma0 mingl-green500">
            {`An email with your message and contact details has been sent to ${card.title}`}
          </p>
        )}
      </div>
    </div>
  );
};

interface GroupEnquiriesProps {
  enquiries: GroupEnquiries_group_enquiries_edges[];
  onCardPressed: (card: MinimalCardFragment) => void;
  viewerCanRemoveEnquiries: boolean;
}

const GroupEnquiries = (props: GroupEnquiriesProps) => {
  const replyToEnquery = useHandledReplyToGroupEnquiryMutation();
  const { setSnackbar } = useSnackbar();
  const [editMode, setEditMode] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const _defaultMessage = 'Failed to delete post';

  const [removeGroupEnquiry] = useRemoveGroupEnquiryMutation();

  const handleReplySubmit = React.useCallback(
    (enquiry, text) => {
      replyToEnquery({
        variables: { input: { groupEnquiryId: enquiry.id, text } },
        refetchQueries: () => [getGroupEnquiriesQueryName()],
      }).then(({ status, message }) =>
        setSnackbar(
          message,
          status === RequestStatusEnum.OK ? 'success' : 'error'
        )
      );
    },
    [replyToEnquery, setSnackbar]
  );

  const handleRemovePost = (enquiryId: string) => {
    removeGroupEnquiry({
      variables: { input: { enquiryId } },
      refetchQueries: () => [getGroupEnquiriesQueryName()],
    }).then(res => {
      const { message, status } = idx(res, _ => _.data!.removeGroupEnquiry) || {
        status: RequestStatusEnum.ERROR,
        message: _defaultMessage,
      };
      setSnackbar(message, status);
      setEditMode(false);
      setAlertOpen(false);
    });
  };

  return (
    <div>
      <div className="flex justify-between mb2">
        <Typography
          variant="h6"
          style={{ marginBottom: muiTheme.spacing(1) }}
          children="Members Answers"
          className="fw6 mv2 white"
        />
        {props.viewerCanRemoveEnquiries && props.enquiries.length !== 0 && (
          <Button
            children={editMode ? 'DONE' : 'EDIT'}
            onClick={() => setEditMode(!editMode)}
          />
        )}
      </div>
      {props.enquiries.map((enquiry, i) => {
        return (
          <div className="flex flex-column mb4" key={i}>
            <EnquiryCard
              enquiry={enquiry.node}
              onReplySubmit={handleReplySubmit}
              onCardPressed={props.onCardPressed}
            />
            {editMode && (
              <p
                className="f5 mingl-red400 self-end ma0 mt2 pointer"
                onClick={() => setAlertOpen(true)}
                children="Remove"
              />
            )}
            <AlertDialog
              open={alertOpen}
              handleClose={() => setAlertOpen(false)}
              title="Remove post?"
              contentText="Are you sure you want to remove this post? This action can not be undone."
              handleAction={() => handleRemovePost(enquiry.node.id)}
            />
          </div>
        );
      })}
      {props.enquiries.length === 0 && (
        <div className="f5 white tc" children={'No answers yet...'} />
      )}
    </div>
  );
};

const GroupEnquiriesSection = (props: {
  groupId: string;
  onCardPressed: (card: MinimalCardFragment) => void;
}) => {
  const { data, loading, error } = useGroupEnquiriesQuery({
    variables: { id: props.groupId },
  });

  const { viewersOwnGroupEnquiry = null, permissions, enquiries } =
    idx(data, _ => _!.group) || {};

  const { viewerCanCreateEnquiry = false } = permissions || {};

  const { viewerCanRemoveEnquiries = false } = permissions || {};

  const groupEnquiries = React.useMemo(
    () =>
      enquiries
        ? enquiries.edges.filter(
            edge => !(idx(edge, _ => _.node.card.owner!.isViewer) || false)
          )
        : [],
    [enquiries]
  );

  return (
    <div className="mv4">
      <div className="fw6 f3 mb2 white" children="Connect with members" />
      <OwnUserEnquiry
        ownEnquiry={viewersOwnGroupEnquiry}
        viewerCanCreateEnquiry={viewerCanCreateEnquiry}
        groupId={props.groupId}
        onCardPressed={props.onCardPressed}
      />
      <GroupEnquiries
        enquiries={groupEnquiries}
        onCardPressed={props.onCardPressed}
        viewerCanRemoveEnquiries={viewerCanRemoveEnquiries}
      />
    </div>
  );
};

export { GroupEnquiriesSection };
